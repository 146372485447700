
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































.app-glide {
  &__flickity {
    display: flex;
  }

  .flickity-viewport {
    @apply tw-w-full;
  }

  .flickity-prev-next-button {
    @apply tw-hidden tw-bg-none tw--translate-y-1/2 sm:tw-flex md:tw-bg-transparent focus:tw-shadow-none;

    &:hover:not(:disabled) {
      @apply tw--translate-y-1/2;
    }

    &.next {
      @apply tw--right-layout-2;
    }

    &.previous {
      @apply tw--left-layout-2;
    }
  }

  .flickity-page-dots {
    @include sm-up {
      display: none;
    }

    .dot {
      transition: all 0.3s ease;

      @apply tw-bg-jb-grey-400 tw-mx-space-1;

      &.is-selected {
        @apply tw-bg-jb-indigo;
      }
    }
  }
}
