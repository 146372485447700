
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























.campaign-story-skeleton {
  pointer-events: none;

  .campaign-background {
    height: 100%;
  }
}
